import React from 'react';
import Project from './Project';
import Music from './Music';
import Splitter from './Splitter';
import ImageClick from '../images/project-click.jpg';
import ImageUnity from '../images/project-unity.png';
import Imagetwittos from '../images/project-tweetosartificiel.png';
import ImageAlbum from '../images/project-album.png';
import ImageWongo from '../images/project-wongo.png';

export default function Projects() {
  return (
    <section className="container flex-column fadeInUp">
      <Splitter title="Professional work" className="mb6" />
      <Project
        title="Wongo"
        description="Wongo is a business development company that specializes in helping businesses grow. They hired me to provide graphic design and development services."
        mode="light"
        project="wongo"
        image={ImageWongo}
        url=""
        product={true}
      />
      <Project
        title="Click"
        description="My app is designed with privacy as a philosophy. It uses end-to-end as the encryption."
        mode="light"
        project="click"
        image={ImageClick}
        isPrivate={true}
        product={true}
      />
      <Project
        title="Unity IX"
        description="The biggest project I started, coming soon."
        mode="dark"
        project="unity"
        image={ImageUnity}
        isPrivate={true}
        product={false}
      />

      <Splitter title="Solo&mdash;projects" className="mv6 pt12" />

      <Music
        title="You & only U"
        description="The latest track I've finished, I am planning to put it in my very first album."
        mode="light"
        project="album"
        image={ImageAlbum}
        // TODO: FINISH THE ALBUM AND REPLACE HERE
        isMusic={true}
      />
      <Project
        title="Twittos Artificiel"
        description="Made an AI, that acts like a french twittos."
        mode="light"
        project="twittosartificiel"
        image={Imagetwittos}
        url="https://twitter.com/twittosAI"
        product={true}
      />
    </section>
  );
}
