import React, { useRef, useState } from "react";
import { ReactComponent as PlayIcon } from "../images/play.svg";
import { ReactComponent as PauseIcon } from "../images/pause.svg";

interface PlayMusicProps {
    audioSource: string;
}

const PlayMusic: React.FC<PlayMusicProps> = ({ audioSource }) => {
    const audioRef = useRef<HTMLAudioElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const togglePlay = () => {
        const audioElement = audioRef.current;
        if (audioElement) {
            if (audioElement.paused) {
                audioElement.play();
                setIsPlaying(true);
            } else {
                audioElement.pause();
                setIsPlaying(false);
            }
        }
    };

    return (
        <div className="play-music">
            <div className={`player ${isPlaying ? "playing" : ""}`}>
                <audio
                    ref={audioRef}
                    src={require(`./${audioSource}`).default}
                />
                <button className="play-button" onClick={togglePlay}>
                    <p className="mr2">Music Project {""}</p>
                    {isPlaying ? (
                        <PauseIcon className="icon-container" />
                    ) : (
                        <PlayIcon className="icon-container" />
                    )}
                </button>
            </div>
        </div>
    );
};

export default PlayMusic;
