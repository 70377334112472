import React from "react";
import Projects from "../components/Projects";
// ONLY IF :  si tu de du gratuit/ open source
// import OpenSource from "../components/OpenSource";
import SectionHeader from "../components/SectionHeader";

export default function Home() {
    return (
        <main>
            <div className="container intro fadeInDown">
                <SectionHeader
                    title="Physics, Mathematics, Software Developer, Sound Engineer & Graphic Designer."
                    description="I'm a driven and motivated individual who wakes up at 4am every day to pursue my passion for math, science, and technology.I'm a creative problem-solver with a strong attention to details, and I love being able to apply my skills to real-world challenges."
                    titleStyle={{ maxWidth: 960 }}
                    descriptionStyle={{ maxWidth: 920 }}
                />
            </div>
            <Projects />
            {/* <OpenSource /> */}
        </main>
    );
}
