import React from "react";
import Avatar from "../images/avatar.jpg";
import About1 from "../images/about-1.png";
import About2 from "../images/about-2.png";
import About3 from "../images/about-3.png";
import About4 from "../images/about-4.png";
import SectionHeader from "../components/SectionHeader";

export default function About() {
    return (
        <main>
            <section className="about">
                <div className="container justify-between">
                    <div className="short fadeInDown">
                        <SectionHeader title="Hello, I'm Armand." />

                        <img
                            className="mobileavatar"
                            alt="Avatar"
                            src={Avatar}
                        />
                        <p>
                            a junior software engineer, designer, and lifelong
                            student with a passion for learning and creating.
                        </p>
                        <p>
                            I've designed a wide range of projects, from{" "}
                            <a
                                href=" "
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Music brands
                            </a>{" "}
                            to{" "}
                            <a
                                href=" "
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                independent
                            </a>
                            {""}, I believe in the importance of adapting to the
                            world around us, which is why I'm interested in
                            working remotely. That being said, I'm always
                            available to be there in person if needed. I'm
                            excited to see where my journey takes me and how I
                            can contribute to the world around me!
                        </p>
                    </div>

                    <img className="fadeInUp" src={Avatar} alt="Avatar" />
                </div>

                <div className="container flex-column mt6 fadeInDown">
                    <p>
                        I'm currently working on my app project with the goal of
                        starting my own company someday. I have a diverse skill
                        set that includes software engineering, design, and
                        physics. I'm always looking for new challenges and
                        opportunities to grow. My dream is to attend Oxford in
                        the next few years to continue my studies in physics and
                        expand my knowledges.
                    </p>
                </div>
                <div className="container flex-column mt6 fadeInDown">
                    <p>
                        I'm passionate about music, travel, and art. Music is a
                        way for me to express my emotions through playing
                        guitar, piano, or composing my own songs. I also love
                        exploring new places and learning about the world around
                        us through travel. Finally, I enjoy painting and drawing
                        as a way to express my creativity and relax. I'd love to
                        chat more about these passions and learn about yours as
                        well!
                    </p>
                </div>

                <div className="container flex-column mt12 fadeInUp">
                    <div className="images">
                        <img src={About1} alt="About 1" />
                        <img src={About2} alt="About 2" />
                        <img src={About3} alt="About 3" />
                        {/* TODO: changer la denière img */}
                        <img src={About4} alt="About 4" />
                    </div>
                </div>
            </section>

            <section className="container flex-column fadeInDown"></section>
        </main>
    );
}
